import { MarkdownResult, renderMarkdown } from './markdown';
import { FilterDefinition } from './types/filters';

export function stripNewLines(s: string): string {
  return s.replaceAll(/\n */g, ' ');
}

export function addPrefixIfMissing(text: string, prefix: string): string {
  return !text.includes(prefix) ? `${prefix} ${text}` : text;
}

export function scrollOnTop(blockId: string = 'description'): void {
  const height = document.getElementById(blockId)?.offsetTop ?? 0;

  window.scrollTo({
    top: height > 200 ? height - 150 : height,
    behavior: 'smooth'
  });
}

export function normalizeText(string: string) {
  return string.normalize('NFC');
}

export function removeAccents(string: string) {
  return string.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
}

export function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, '');

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from =
    'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
  const to =
    'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-');

  return str;
}

export function filterOutNullOrUndefined<T>(item: T | undefined): item is T {
  return item !== null && item !== undefined;
}

export function unSlugify(str: string): string {
  return str
    .split('-')
    .map(word => capitalize(word))
    .join(' ');
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function anchorTo(id: string): void {
  if (id.length > 0) window.location.hash = `#${id}`;
}

export const nextTwelveMonthsFilters = (): FilterDefinition<'months'>[] => {
  const months: FilterDefinition<'months'>[] = [];
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  for (let i = 0; i < 12; i++) {
    const month = currentMonth + i;
    const year = month > 11 ? currentYear + 1 : currentYear;
    const adjustedMonth = month % 12;
    const monthName = capitalize(
      new Date(year, adjustedMonth, 1).toLocaleString('default', { month: 'long' })
    );

    months.push({
      id: `${monthName}-${year}`,
      name: `${monthName} ${year}`,
      value: `${year}-${String(adjustedMonth + 1).padStart(2, '0')}`,
      identifier: 'months'
    });
  }

  return months;
};

export function replacePlaceholders(
  placeholder: string,
  values: Record<string, string | number | boolean>
) {
  return placeholder.replace(/{{\s*([^{}]+)\s*}}/g, (match, key) =>
    values[key] ? String(values[key]) : match
  );
}

export function replaceStrongHtml(text: string) {
  const regex = /\*\*(.*?)\*\*/g;
  const replacedText = text.replace(regex, (_, strong) => {
    return `<strong>${strong}</strong>`;
  });

  return replacedText;
}

export function getRenderedMarkdown({
  content,
  expanded,
  maxLength = 400
}: {
  content: string;
  expanded: boolean;
  maxLength?: number;
}): MarkdownResult {
  if (content.length > maxLength) {
    return renderMarkdown(content, expanded ? 0 : maxLength);
  }
  return renderMarkdown(content);
}

export function replaceHashes(text: string): string {
  let count = 1;
  const replaceHashes = text.replace(/##/g, () => {
    return (count++).toString();
  });
  return replaceHashes;
}

export function formatEventDate(dates: { startDate?: string; endDate?: string }): string {
  let formatedDate = '';

  // events dates stored in strapi are in french format ex : 22 mai 2024 and need to be parsed and reformated to be compared
  function parseFrenchDate(dateStr: string): Date | undefined {
    const months: Record<string, number> = {
      janvier: 0,
      février: 1,
      mars: 2,
      avril: 3,
      mai: 4,
      juin: 5,
      juillet: 6,
      août: 7,
      septembre: 8,
      octobre: 9,
      novembre: 10,
      décembre: 11
    };
    const parts = dateStr.split(' ');
    const day = parts[0] ? parseInt(parts[0]) : undefined;
    const month = parts[1] ? months[parts[1].toLowerCase()] : undefined;
    const year = parts[2] ? parseInt(parts[2]) : undefined;
    return year && month && day ? new Date(year, month, day) : undefined;
  }

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
  const locale = 'fr-FR';

  if (dates.startDate && dates.endDate && dates.startDate !== dates.endDate) {
    const startDateFormat = parseFrenchDate(dates.startDate);
    const endDateFormat = parseFrenchDate(dates.endDate);

    const startDateFormatted = new Intl.DateTimeFormat(locale, {
      ...options,
      year: undefined
    }).format(startDateFormat);
    const endDateFormatted = new Intl.DateTimeFormat(locale, options).format(endDateFormat);

    formatedDate = `Du ${startDateFormatted} au ${endDateFormatted}`;
  } else if (dates.startDate) {
    const startDateFormat = parseFrenchDate(dates.startDate);
    const startDateFormatted = new Intl.DateTimeFormat(locale, options).format(startDateFormat);

    formatedDate = `Le ${startDateFormatted}`;
  }

  return formatedDate;
}

export function formatEventDateISO(dates: { startDate?: string; endDate?: string }): string {
  let formatedDate = '';

  function parseISODate(dateStr: string): Date | undefined {
    const parts = dateStr.split('-');
    const year = parts[0] ? parseInt(parts[0]) : undefined;
    const month = parts[1] ? parseInt(parts[1]) - 1 : undefined;
    const day = parts[2] ? parseInt(parts[2]) : undefined;
    return year && month !== undefined && day ? new Date(year, month, day) : undefined;
  }

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
  const locale = 'fr-FR';

  if (dates.startDate && dates.endDate && dates.startDate !== dates.endDate) {
    const startDateFormat = parseISODate(dates.startDate);
    const endDateFormat = parseISODate(dates.endDate);

    const startDateFormatted = new Intl.DateTimeFormat(locale, {
      ...options,
      year: undefined
    }).format(startDateFormat);
    const endDateFormatted = new Intl.DateTimeFormat(locale, options).format(endDateFormat);

    formatedDate = `Du ${startDateFormatted} au ${endDateFormatted}`;
  } else if (dates.startDate) {
    const startDateFormat = parseISODate(dates.startDate);
    const startDateFormatted = new Intl.DateTimeFormat(locale, options).format(startDateFormat);

    formatedDate = `Le ${startDateFormatted}`;
  }

  return formatedDate;
}
